.mg-table {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
}
.mg-table-footer {
  position: sticky;
  bottom: 0;
  background: hsla(0, 0%, 100%, 0.8);
  display: flex;
  flex-wrap: wrap-reverse;
}
.mg-table-footer .vf-pagination {
  min-width: 75%;
  flex-grow: 2;
}
.mg-table-footer .vf-pagination .vf-pagination__item {
  align-self: center;
}
.mg-table-footer > label {
  flex-grow: 1;
  padding: 0.5em;
  color: #707372;
}

.mg-table-caption > div {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}
.mg-table-caption > div > div {
  display: flex;
  gap: 1em;
  align-items: center;
}
