.arrow-icon {
  /*transition: transform 0.7s ease, scale 0.7s ease;*/
  transition: transform 0.7s ease, scale 0.7s ease, margin-left 0.7s ease;
  margin-left: 0.2em;
}

.rotating-link:hover .arrow-icon {
  transform: rotateX(180deg) scale(1.5) translateX(0.5em);
}


.mg-main-menu {
  position: sticky;
  top: 0;
  z-index: 2000;
  /*display: flex;*/
  border-bottom: 1px solid gray;
  background-color: rgba(255, 255, 255, 0.95);
  /*padding-top: 0.3rem;*/
}
@media (max-width: 768px) {
  .mg-main-menu {
    justify-content: space-between;
  }
}

.mg-search-tabs .vf-tabs__link.is-active {
  z-index: 100;
}
.mg-search-tabs .vf-tabs__list::before {
  z-index: 110;
}

.mg-main-menu > img {
  height: 2rem;
  margin-right: 2rem;
  padding-bottom: 0.1rem;
}

.mg-logo-sticky {
  filter: invert(24%) sepia(14%) saturate(3697%) hue-rotate(111deg)
    brightness(86%) contrast(92%);
}
