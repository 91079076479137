.mg-loading-overlay-container {
  position: relative;
}
.mg-loading-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
  background: lightgray;
  display: list-item;
  animation: palpitation 1s linear infinite;
  z-index: 10;
}

@keyframes palpitation {
  0%,
  35% {
    opacity: 0.4;
  }
  20%,
  50% {
    opacity: 0.2;
  }
}
