.App {
  margin: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

body div#root a.mg-link {
  color: #222;
  text-decoration: none;
  cursor: pointer;
}

body div#root a.mg-link:hover,
body div#root a.mg-link:active,
body div#root a.mg-link:focus,
body div#root a.mg-link:focus-within {
  color: #007c82;
}
body div#root a.mg-link:hover {
  text-decoration: underline;
}

div.mg-right {
  text-align: right;
}
div.mg-center {
  text-align: center;
}

span.mg-number {
  font-size: 0.9rem;
  font-family: monospace;
  background: #d3d3d3;
  color: #3d3d3d;
  margin-left: 0.5rem;
  padding: 0.2rem;
  flex-shrink: 0;
  align-self: flex-start;
}
button.mg-button-as-link {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 1em;
  background-color: transparent;
  border: 0;
}
button.mg-button-as-tab {
  padding: 0 1rem;
  margin: 0;
  font-size: 1em;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background: transparent;
  cursor: pointer;
}

button.mg-button[disabled],
.vf-button.mg-button-disabled {
  background-color: lightgrey;
  border-color: lightgrey;
  transform: none;
  box-shadow: 8px 8px 0 #ddd, -5px -5px rgb(0 0 0 / 0%);
  cursor: default;
}

.mg-flex {
  display: flex;
  gap: var(--page-grid-gap, 1em);
  align-items: flex-start;
}

.mg-list__item {
  margin-left: 0.6rem;
}

fieldset.mg-fieldbox {
  border: 1px solid #eeeeee;
  padding: 4px;
}

fieldset.mg-fieldbox:hover {
  border: 1px solid #dddddd;
}
fieldset.mg-fieldbox .vf-form__legend {
  font-size: 1.2rem;
}
fieldset.mg-fieldbox label {
  display: block;
}

.mg-grid-30-70 {
  grid-template-columns: 30% 70%;
}

html
  .vf-content
  .vf-table--striped
  .vf-table__body
  .vf-table__row:not(.vf-table__row--selected):hover {
  background-color: #fffadc;
}

pre.vf-code-example__pre > button {
  float: right;
  margin: 0;
}

.break-anywhere {
  overflow-wrap: anywhere;
}

.nowrap {
  white-space: nowrap;
}

.eez-abbr {
    cursor: help;
    text-decoration: none;
}

#mgnify-mega-menu .vf-navigation__link:visited:hover {
  color: #3b6fb6;
  text-decoration: underline;
}